import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import { App } from './layouts';
import { Home, NotFound } from './pages';

export const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route
                path="/"
                element={
                    <App key={0} />
                }
            >
                <Route
                    index
                    element={
                        <Home />
                    }
                />
                <Route
                    path="*"
                    element={
                        <NotFound />
                    }
                />
            </Route>
            <Route
                path="/mn"
                element={
                    <App key={1} />
                }
            >
                <Route
                    index
                    element={
                        <Home />
                    }
                />
                <Route
                    path="*"
                    element={
                        <NotFound />
                    }
                />
            </Route>
        </Routes>
    </BrowserRouter>
);