import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, Typography } from '@mui/material'

export default function Supplier(props) {
    const { t } = useTranslation();

    const {
        name,
        logo,
        description,
        link
    } = props;

    return (
        <Card
            elevation={0}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: "1px solid #e0e0e0",
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <CardContent
                    sx={{
                        flex: '1 0 auto'
                    }}
                >
                    <Typography
                        variant="h6"
                        textTransform="uppercase"
                        fontSize={{
                            xs: 16,
                            md: 20
                        }}
                        sx={{
                            marginBottom: 0.5
                        }}
                    >
                        {name}
                    </Typography>
                    <Typography
                        variant="body1"
                        fontSize={{
                            xs: 14,
                            md: 16
                        }}
                        sx={{
                            marginBottom: 2
                        }}
                    >
                        {description}
                    </Typography>
                    <Button
                        component="a"
                        size="small"
                        color="primary"
                        href={link}
                        target="_blank"
                    >
                        {t("visit website")}
                    </Button>
                </CardContent>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={2}
            >
                <Box
                    component="img"
                    sx={{
                        width: 'auto',
                        height: {
                            xs: 32,
                            sm: 40,
                            md: 48,
                            lg: 64
                        }
                    }}
                    src={logo}
                />
            </Box>
        </Card>
    );
}