import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { AppHeader, AppFooter } from '../components';

export default function App() {
    return (
        <Box>
            <AppHeader />
            <Outlet />
            <AppFooter />
        </Box>
    );
}

