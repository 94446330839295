import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    shape: {
        borderRadius: 8
    },
    palette: {
        primary: {
            main: '#129B49'
        },
        secondary: {
            main: '#383838'
        },
        background: {
            main: '#f9f9f9'
        },
        text: {
            primary: '#383838'
        }
    },
    typography: {
        h1: {
            fontFamily: 'Open Sans',
            fontWeight: 700
        },
        h2: {
            fontFamily: 'Open Sans',
            fontWeight: 700
        },
        h3: {
            fontFamily: 'Open Sans',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'Open Sans',
            fontWeight: 700
        },
        h5: {
            fontFamily: 'Open Sans',
            fontWeight: 700
        },
        h6: {
            fontFamily: 'Open Sans',
            fontWeight: 700
        },
        subtitle1: {
            fontFamily: 'Open Sans',
            fontWeight: 700
        },
        subtitle2: {
            fontFamily: 'Open Sans',
            fontWeight: 700
        },
        body1: {
            fontFamily: 'Open Sans',
            fontWeight: 300
        },
        body2: {
            fontFamily: 'Open Sans',
            fontWeight: 300
        },
        button: {
            fontFamily: 'Open Sans',
            fontWeight: 700,
            textTransform: 'none'
        },
        caption: {
            fontFamily: 'Open Sans',
            fontWeight: 400
        },
        overline: {
            fontFamily: 'Open Sans',
            fontWeight: 300
        }
    },
});