import * as React from 'react';
import { Container } from '@mui/system';
import { Grid, Typography } from '@mui/material';

export default function NotFound() {
    return (
        <Container maxWidth="xl">
            <Grid
                minHeight="80vh"
                justifyContent="center"
                alignItems="center"
                container
            >
                <Grid
                    xs={12}
                    item
                >
                    <Typography
                        variant="h2"
                        textAlign="center"
                    >
                        404
                    </Typography>
                    <Typography
                        variant="h5"
                        textAlign="center"
                    >
                        This page could not be found.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

