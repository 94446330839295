import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid } from '@mui/material';

export default function AppHeader() {
    const { i18n } = useTranslation();
    const { pathname } = useLocation();

    return (
        <Container maxWidth="xl">
            <Box paddingY={3}>
                <Grid
                    direction="row"
                    alignItems="center"
                    container
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Box
                            component="img"
                            alt="logo"
                            src="images/Wholesomefoods.png"
                            sx={{
                                width: 'auto',
                                height: {
                                    xs: 32,
                                    md: 40,
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        {pathname === '/' ? (
                            <Button
                                component={Link}
                                to="/mn"
                                onClick={() => {
                                    i18n.changeLanguage('mn');
                                }}
                                variant="text"
                                sx={{
                                    color: '#000',
                                    fontWeight: 300
                                }}
                            >
                                MN
                            </Button>
                        ) : (
                            <Button
                                component={Link}
                                to="/"
                                onClick={() => {
                                    i18n.changeLanguage('en');
                                }}
                                variant="text"
                                sx={{
                                    color: '#000',
                                    fontWeight: 300
                                }}
                            >
                                EN
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}