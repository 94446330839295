import { useTranslation } from 'react-i18next';
import { Container, Box, Divider, IconButton, Grid, Stack, Typography } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

export default function AppFooter() {
    const { t } = useTranslation();

    return (
        <Box>
            <Divider />
            <Container maxWidth="xl">
                <Box
                    sx={{
                        py: 12.5
                    }}
                >
                    <Grid
                        container
                        spacing={5}
                        justifyContent="space-between"
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            item
                        >
                            <Box
                                component="img"
                                alt="logo"
                                src="/images/Wholesomefoods.png"
                                sx={{
                                    width: 'auto',
                                    height: {
                                        xs: 32,
                                        md: 40,
                                    }
                                }}
                            />
                            <Typography
                                variant="body1"
                                fontSize={{
                                    xs: 14,
                                    md: 16
                                }}
                                sx={{
                                    marginTop: 3
                                }}
                            >
                                (976) 77102222
                            </Typography>
                            <Typography
                                variant="body1"
                                fontSize={{
                                    xs: 14,
                                    md: 16
                                }}
                            >
                                hello@wholesomefoods.mn
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Typography
                                variant="h5"
                                fontSize={{
                                    xs: 20,
                                    md: 24
                                }}
                            >
                                {t("office")}
                            </Typography>
                            <Typography
                                variant="body1"
                                fontSize={{
                                    xs: 14,
                                    md: 16,
                                    lg: 16
                                }}
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                {t("address")}
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                <IconButton
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        color: '#323B35'
                                    }}
                                >
                                    <Facebook />
                                </IconButton>
                                <IconButton
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        color: '#323B35'
                                    }}
                                >
                                    <Instagram />
                                </IconButton>
                                <IconButton
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        color: '#323B35'
                                    }}
                                >
                                    <LinkedIn />
                                </IconButton>
                                <IconButton
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        color: '#323B35'
                                    }}
                                >
                                    <Twitter />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Divider />
            <Box padding={3}>
                <Typography
                    variant="body2"
                    textAlign="center"
                >
                    &copy; {new Date().getFullYear()} {t("copyright")}
                </Typography>
            </Box>
        </Box>
    );
}